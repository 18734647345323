import { LanguageFile } from './language.interface'

export const de: LanguageFile = {
    toast: {
        'Welcome': 'Willkommen',
        'Goodbye': 'Auf Wiedersehen',
        'Something went wrong': 'Etwas ist schiefgelaufen',
        success: {
            'requestPasswordReset': 'Eine E-Mail mit dem Link zum Setzen Ihres neuen Passworts wurde versand',
            'passwordSet': 'Ihr Passwort wurde erfolgreich geändert, Sie können sich nun einloggen.',
            'driverInviteSend': 'Einladung erneut verschickt',
	    'generic': 'Erfolg'
        },
        errors: {
            'GraphQL error: EMAIL_ALREADY_IN_USE': 'Die Email-Adresse ist bereits vergeben.'
        }
    },
    general: {},
    confirmModal: {
        confirm: 'Bestätigen',
        cancel: 'Abrechen'
    },
    login: {
        hint: 'Login zum Webportal für Unternehmen',
        login: 'Login',
        error: 'Email oder Passwort ist falsch',
        confirmWrongAccount: 'Dieses Webportal ist nur für Unternehmen. Bitte butzen sie die Fifty-Fifty App auf ihrem Smartphone.',
        forgottenPassword: 'Neues Passwort anfordern',
        backToLogin: 'Zurück zum Login',
        requestDeletion: 'Account löschen'
    },
    resetPassword: {
        hint: 'Bitte geben sie ein neues Passwort ein.',
        password: 'Passwort',
        confirmPassword: 'Passwort bestätigen',
        reset: 'Zurücksetzen',
        errors: {
            length: 'Das Passwort muss mindestens 6 Zeichen lang sein.',
            same: 'Die Passwörter stimmen nicht überein.',
            emailOrToken: 'Der Link zum Zurücksetzen des Passworts ist ungültig.'
        }
    },
    requestPasswordReset: {
        hint: 'Passwort Reset beantragen',
        email: 'E-Mail'
    },
    requestDeletion: {
        hint: 'Account löschen',
        success: 'Löschen im Email-Postfach bestätigen um den Vorgang abzuschließen.',
        form: {
          input: {
            email: "Email angeben",
            submit: 'Anfordern'
          }
        }
    },
    invoice: {
        hint: 'Abrechnung Übersicht',
        head: 'Alle registrierten oder importierten Taxiunternehmen und Mietwagenunternehmen',
        title: 'Download Gesamtabrechnung',
        info: 'Die monatliche Gesamtabrechnung kann als CSV Datei nach Ende der Laufzeit heruntergeladen werden. Der Download für den Leistungszeitraumes wird immer am ersten Tag des neuen Leistungszeitraumes bereitgestellt.',
        table: {
            month: 'Monat',
            year: 'Jahr',
            accountingPeriod: 'Abrechnungszeitraum',
            download: 'Download',
	        button: {
                download: "Fahrten (CSV)",
                downloadsummary: "Zusammenfassung (CSV)",
                excel: '(Excel)',
                sepa: 'Zusammenfassung (SEPA)',
            },
        },
        documentLabel: {
            id: 'Ticket Nr.',
            createdAt: 'Erfassung',
            departure: 'Abfahrt',
            destination: 'Ziel',
            distance: 'KM',
            fare: 'Betrag',
            payout: 'Auszuzahlen',
            payoutLimit: 'Preisdeckel',
            concessionNumber: 'Ordnungsnummer',
            rideStartDate: 'Fahrtbeginn',
            rideEndDate: 'Fahrtende',
            companyName: 'Unternehmername',
            cancellationReason: 'StornierungsGrund',
            rideStartLocation: 'Abfahrt-Location',
            rideEndLocation: 'Ankunft-Location',
            status: 'Status',
        },
        summaryDocumentLabel: {
            id: 'Unternehmer-ID',
            name: 'Unternehmername',
            fare: 'Summe',
            payout: 'Auszuzahlen',
            iban: 'IBAN',
            bic: 'BIC',
            recipient: 'Empfänger',
        }
    },
    companies: {
        hint: 'Unternehmen Übersicht',
        head: 'Alle registrierten oder importierten Taxiunternehmen',
        title: 'Aktivieren / Deaktivieren der Unternehmen',
        info: 'Hier werden alle Unternehmen gelistet die sich angemeldet haben oder als Firmendaten importiert wurden.',
        confirmDeactivate: 'Wollen sie die Freischaltung von {company} wirklich aufheben. Das Unternehmen muss bei erneuter Freischaltung das Passwort neu setzen.',
        confirmActivate: 'Wollen sie {company} wirklich freischalten. Das Unternehmen bekommt eine Email um das Passwort neu zu setzen.',
        table: {
            companies: 'Unternehmen',
            adress: 'Adresse',
            contact: 'Kontakt',
            date: 'Datum',
            status: 'Status',
            active: 'deaktivieren',
            inactive: 'freischalten',
            inactive2: 'nicht freigeschaltet',
            blocked: 'blockiert',
            active2: 'aktiv',
            downloadHint: 'Anhang herunterladen',
        }
    },
    driver: {
        headLine: {
            title: 'Taxifahrer eintragen & einladen'
        },
        title: 'Neue Taxifahrer eintragen',
        info: 'Wenn Sie neue Fahrer eingetragen und Sie anschließend auf den Button „Einladung verschicken“ gedrückt haben, erhalten die Fahrer eine E-Mail mit dem Download Link der App sowie ihren Zugangsdaten, die die Fahrer direkt in der App nutzen können.',
        invite: {
            name: {
                label: 'Empfänger',
                placeholderFirstName: 'Vorname',
                placeholderLastName: 'Nachname'
            },
            email: {
                label: 'E-Mail Adresse des Fahrers',
                placeholder: 'E-mail'
            },
            send: 'Einladung verschicken',
            succsess: 'Einladung erfolgreich vesendet',
            failure: 'Einladung konnte nicht vesendet werden'
        },
        table: {
            head: {
                taxiDrivers: 'Taxifahrer',
                status: 'Status',
                edit: {
                    title: 'Bearbeiten',
                    sendAgain: 'Erneut verschicken',
                    activate: 'Aktivieren',
                    deactivate: 'Deaktivieren'
                }
            },
            body: {
                status: {
                    active: 'Aktiviert',
                    deactivate: 'Deaktiviert',
                    sentInvitation: 'Einladung verschickt'
                }
            }
        }
    },
    register: {
        hint: 'Für Taxiunternehmen und ihre Fahrer',
        info: {
            title: 'Mitmachen und Fifty Fifty fahren.',
            1: 'Registrieren Sie Ihr Unternehmen mit Ihren Bankverbindungen.',
            2: 'Tragen Sie Ihre Fahrer über dieses Website ein.',
            3: 'Die Fahrer erhalten einen Downloadlink zur App mit Ihren Zugangsdaten',
            4: 'Die Fahrkosten werden bei Nutzung der App wie folgt getragen:',
            fifty: 'Fifty',
            percent: '50%',
            customerPays: 'Bezahlt der Fahrgast',
            wePay: 'Bezahlen wir',
            proceed: 'Ihr Taxiunternehmen registrieren',
            hdiw: '>> Wie funktioniert es?'
        },
        form: {
            title: 'Taxiunternehmen registrieren',
            secondTitle: 'Die Seite ist SSL verschlüsselt',
            step1: '1. Angaben zu Ihrem Taxiunternehmen',
            companyName: 'Name Taxiunternehmen',
            companyNamePh: 'Firmenname',
            contactInfo: 'Kontaktinformationen',
            contactInfoNamePh: 'Name Ansprechpartner',
            contactInfoPhonePh: 'Telefonnummer',
            contactInfoMailPh: 'E-Mail',
            adress: 'Adresse',
            zipPh: 'Postleitzahl',
            streetPh: 'Straße & Hausnummer',
            cityPh: 'Stadt',
            countryPh: 'Land',
            step2: '2. Bankdaten angeben',
            recipient: 'Empfänger',
            recipientPh: 'Kontoinhaber',
            iban: 'IBAN',
            ibanPh: '22 stellige Nummer (D)',
            bic: 'BIC',
            bicPh: '(optional)',
            agbAndPrivacy: {
                prefix: 'Ich habe die',
                agb: 'AGB',
                middle: 'und den',
                privacy: 'Datenschutz',
                suffix: 'gelesen'
            },
            submit: 'Jetzt registrieren',
            info: {
                strong: 'Info: ',
                text: ' Nach einer erfolgreichen Registrierung und Freischaltung Ihres Unternehmenzuganges erhalten sie eine gesonderte E-mail, mit der Sie sich erneut auf der Seite einloggen können und Ihre Fahrer sowie Konzessionsnummern angeben können.'
            },
            upload: 'Nachweise (Konzessionsauszug etc.)',
            photo: 'Foto aufnehmen',
            or: 'oder',
            file: 'anhang.png'
        },
        complete: {
            title: 'Herzlichen Dank für die Registrierung',
            content: 'Nachdem wir Ihre Anmeldung freigeschaltet haben, erhalten sie eine gesonderte E-Mail. Dann können sie ihr persönliches  Passwort vergeben, neue Fahrer anlegen und sie zu der Fifty Fifty App einladen.',
            bye: 'Gute Fahrt!'
        }
    },
    driverRegister: {
        setPassword: {
            hint: 'Setzen Sie ihr Passwort',
            submit: 'Speichern'
        },
        complete: {
            title: 'Herzlichen Dank für die Registrierung',
            content: `Sie sind nun registriert. Bitte laden Sie sich unsere App herunter und loggen sich dort ein.`,
            bye: 'Gute Fahrt!'
        }
    },
    header: {
        companies: 'Unternehmen',
        invoice: 'Abrechnungen',
        register: 'Registrieren',
        hdiw: 'Wie funktioniert es?',
        login: '> Login',
        logout: '> Logout',
	drivers: 'Fahrer',
	profile: 'Unternehmensprofil'
    },
    footer: {
        contact: 'Kontakt',
        imprint: 'Impressum',
        agbLink: 'AGB',
        agb: 'Allgemeine Geschäftsbedingungen',
        privacy: 'Datenschutzerklärung',
        copyright: 'Copyright 2019-{currentYear} MIL'
    },
    validator: {
        minLength: 'muss mindestens {length} Zeichen lang sein',
        required: 'Das Feld ist notwendig',
        email: 'Das ist keine gültige Email',
        phone: 'Das ist keine gültige Telefonnummer',
        zip: 'Das ist keine gültige Postleitzahl',
        iban: 'Das ist keine gültige IBAN'
    },
    page: {
        imprint: {
            title: 'Impressum'
        },
        about: {
            title: 'Das Fifty-Fifty-Taxi'
        },
        privacy: {
            title: 'Datenschutzerklärung'
        },
        tos: {
            title: 'Projektbeschreibung / Nutzungsbedingungen'
        },
        deleteAccount: {
            title: 'Account löschen'
        },
        faq: {
            title: 'Häufige Fragen'
        }
    },
    profile: {
	    hint: 'Für Taxiunternehmen und ihre Fahrer'
    },
    company: {
	    profile: {
		    form: {
			    title: 'Unternehmensprofil aktualisieren',
			    submit: 'Speichern'
		    }
	    }
    }
}
